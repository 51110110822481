import React, { useState } from 'react'
import './Sidebar.css'
import HomeIcon from '@mui/icons-material/Home';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import PaymentIcon from '@mui/icons-material/Payment';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { NavLink } from 'react-router-dom';
import ComingSoon from '../../../Pages/ComingSoon/ComingSoon';

function Sidebar() {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <aside className="sidebar">
      <nav>
        <ul>
          <NavLink to="home"><li><HomeIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>Home</span></li></NavLink>
          <NavLink to="bookings"><li><WifiCalling3Icon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>Bookings</span></li></NavLink>
          <NavLink to="prioritydm"><li><VoiceChatIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>Priority DM</span></li></NavLink>
          <NavLink to="services"><li><MenuBookIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>Services</span></li></NavLink>
          <NavLink to="payments"><li><PaymentIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>Payments</span></li></NavLink>
          <li onClick={setIsVisible}><CardGiftcardIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>Rewards</span></li>
          <li onClick={setIsVisible}><NotificationsNoneIcon sx={{ fontSize: 25, marginRight: "2px", marginTop: "-2px" }} /> <span>What's New</span></li>
        </ul>
      </nav>
      <ComingSoon isVisible={isVisible} setIsVisible={setIsVisible} />

    </aside>
  )
}

export default Sidebar