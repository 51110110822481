import React, { useState } from "react";
import "./Footer.css";
import { NavLink, Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Divider from '@mui/material/Divider';
import ComingSoon from "../../Pages/ComingSoon/ComingSoon";


const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <div className="footer flex">
        <div className="footer-brand-text">
          <div>
            <Link to={"/"}>
              <span style={{ color: "white" }}>Code</span>
              <span style={{ color: "var(--primarty-text-color)" }}>Queue</span>
            </Link>
          </div>
          <p className="td">The Ultimate Guide To Ace SDE Interview</p>
        </div>

        <div className="">
          <header>Quick Links</header>
          <div className="flex-col common">
            <NavLink to={"/"} className="td hover">
              Home
            </NavLink>
            <li className="td hover" onClick={setIsVisible}>
              About Us
            </li>
            <li className="td hover" onClick={setIsVisible}>
              Contact
            </li>
            {/* <NavLink to={"/dashboard"} className="td hover">
              Dashboard
            </NavLink> */}
            <li className="td hover" onClick={setIsVisible}>
              Important
            </li>
          </div>
        </div>

        <div className="">
          <header>Legal</header>
          <div className="flex-col common">
            <li className="td hover" onClick={setIsVisible}>
              Privacy & Policy
            </li>
            <li className="td hover" onClick={setIsVisible}>
              Term of Use
            </li>
            <li className="td hover" onClick={setIsVisible}>
              Refund & Cancellation Policy
            </li>
          </div>
        </div>

        <div className="flex-col">
          <header>GET IN TOUCH</header>
          <div className="flex-col common">
            <NavLink to={"/email"} className="td hover">
              Support@CodeQueue.in
            </NavLink>
            <div className="footer-social-media flex">
              <a href="https://www.linkedin.com/in/sstyendra13/" target="_blank" rel="noopener noreferrer" className="hover" ><LinkedInIcon/></a>
              <a href="https://x.com/ssatyendrak13" target="_blank" rel="noopener noreferrer" className="hover" ><TwitterIcon/></a>
              <a href="https://www.instagram.com/ssatyendra13/" target="_blank" rel="noopener noreferrer" className="hover" ><InstagramIcon/></a>
            </div>
          </div>
        </div>
      </div>
      <Divider/>
      <div className="copyright">
        Copyright &copy; 2024 CodeQueue Technologies Pvt Ltd. All Rights
        Reserved.
      </div>
      <ComingSoon isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
};

export default Footer;
