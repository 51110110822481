import React, { useState } from 'react';
import './services.css';
import { useNavigate } from 'react-router-dom';

const MentorshipCard = ({ item }) => {
  const navigate = useNavigate();
  
  const goToBookingPage = () => {
    navigate('/dashboard/services/booktimeslot', { state: { item:item } });
  };

  return (
    <div className="mentorship-card">
      <h2>{item.name}</h2>
      <div className="details">
        <div className="time-info">
          <span className="icon">📅</span>
          <span>{item.time}</span><br />
          <span className='mx-3'>{item.type}</span>
        </div>
        <div className="price-info">
          <button className="price-button" onClick={goToBookingPage}>₹{item.price}+</button>
        </div>
      </div>
    </div>
  );
};

function Services() {
  const mentorshipData = [
    { name: '1:1 Mentorship', time:'30 mins', type:'Video Meeting', price: 200 },
    { name: '1:1 Call', time:"30 mins", type:'Video Meeting', price: 200 },
    { name: '1:1 Call', time:"30 mins", type:'Video Meeting', price: 200 },
    { name: 'Priority DM', time:"Replies in 2 days", type:'Priority DM', price: 0 },
  ];

  const [filter, setFilter] = useState('All');

  const filteredData = mentorshipData.filter(item => {
    if (filter === 'All') return true;
    return item.name.includes(filter);
  });

  return (
    <>
    <div className="mentorship-container">
      <div className="filter-buttons">
        <button
          className={`filter ${filter === 'All' ? 'active' : ''}`}
          onClick={() => setFilter('All')}
        >
          All
        </button>
        <button
          className={`filter ${filter === '1:1 Call' ? 'active' : ''}`}
          onClick={() => setFilter('1:1 Call')}
        >
          1:1 Call
        </button>
        <button
          className={`filter ${filter === 'Priority DM' ? 'active' : ''}`}
          onClick={() => setFilter('Priority DM')}
        >
          Priority DM
        </button>
      </div>
      <div className="mentorship-grid">
        {filteredData.map((item, index) => (
          <MentorshipCard key={index} item={item} />
        ))}
      </div>
    </div>
    </>
  );
}

export default Services;
