import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Layout from '../Components/Layout'; // Import the layout component
import Home from '../Components/Home/Home';
// import Contact from '../Components/Contact/Contact';
// import Careers from '../Components/Careers/Careers';
import Signup from '../Components/Signup/Signup';
import Login from '../Components/Login/Login';
import ForgotPassword from '../Components/Login/ForgotPassword';
// import About from '../Components/About/About';
import Dashboard from '../Components/Dashboard/Dashboard';
import DashboardHome from '../Components/Dashboard/Home.jsx/Home';
import Booking from '../Components/Dashboard/Bookings/Booking';
import Priority from '../Components/Dashboard/PriorityDM/Priority';
import Payments from '../Components/Dashboard/Payments/Payments';
import User from '../Components/Dashboard/Bookings/User';
import Services from "../Components/Dashboard/Services/Services";
import BookTimeSlot from "../Components/Dashboard/Services/BookTimeSlot/BookTimeSlot";
import ComingSoon from "../Pages/ComingSoon/ComingSoon";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";


const Routes = () => {
    const { token } = useAuth();

    const routesForPublic = [ 
        {
            path: "/",
            element: <Layout><Home /></Layout>, // Wrap with Layout
        },
        {
            path: "*",
            element: <Layout><PageNotFound/></Layout>
        }
    ];

    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />,
            children: [
                {
                    path: "/dashboard",
                    element: <Layout><Dashboard /></Layout>, // Wrap with Layout
                    children: [
                        {
                            index: true, // This indicates that this route is the default for its parent
                            element: <Navigate replace to="home" />, // Redirects to 'home'
                        },
                        {
                            path: "home",
                            element: <DashboardHome />, 
                        },
                        {
                            path: "bookings",
                            element: <Booking />, 
                        },
                        {
                            path: "bookings/:id",
                            element: <User />
                        },
                        {
                            path: "services",
                            element: <Services />
                        },
                        {
                            path: "services/booktimeslot",
                            element: <BookTimeSlot />
                        },
                        {
                            path: "prioritydm",
                            element: <Priority />
                        },
                        {
                            path: "payments",
                            element: <Payments />
                        },
                    ],
                },
            ],
        },
    ];

    const routesForNotAuthenticatedOnly = [
      
        {
            path: "/login",
            element: <Layout><Login /></Layout>, // Wrap with Layout
        },
        {
            path: "/signup",
            element: <Layout><Signup /></Layout>, // Wrap with Layout
        },
        {
            path: "/forgot-password",
            element:<Layout><ForgotPassword/></Layout>
        }
    ];

    const router = createBrowserRouter([
        ...routesForPublic,
        ...routesForAuthenticatedOnly,
        ...(!token ? routesForNotAuthenticatedOnly : []),

    ]);

    return <RouterProvider router={router} />;
};

export default Routes;
