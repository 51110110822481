import React from "react";
import "./HeroSection.css";
import { NavLink } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const HeroSection = () => {

  const heroCards = [
    { name: "Satyendra Kumar", designation: "Software Engineer", company: "Capgemini Technology", tech: "MERN Stack", experience: "1yr", desc: "helps people get a job" },
    { name: "Satyendra Kumar", designation: "Software Engineer", company: "Capgemini Technology", tech: "MERN Stack", experience: "1yr", desc: "helps people get a job" },
  ];

  return (
    <div className="hero flex">
      <div className="hero-left flex-col">
        <div className="hero-header">
          Personalized mentorship for careers advancement
        </div>
        <div className="hero-para">
          Elevate your expertise by learning from industry leaders. Start your
          journey towards success today.
        </div>
        <div className="hero-btn flex">
          <NavLink to={"/login"} className="secondary-btn hero-btn">Learn More <ArrowForwardIcon/></NavLink>
          <NavLink to={"/signup"} className="primary-btn hero-btn"style={{ color: "white" }}>Book Session</NavLink>
        </div>
      </div>

      <div className="hero-right flex">
        {
          heroCards.map((item, index)=>{
            return (
              <div className="hero-card flex-col" key={index}>
                <div className="hero-image"></div>
                <div className="hero-name">{item.name}</div>
                <div className="hero-designation">{item.designation}</div>
                <div className="hero-company">{item.company}</div>
                <div className="hero-eoe"><span>Tech :</span>{item.tech}</div>
                <div className="hero-eoe"><span>Year of Ex :</span>{item.experience}</div>
                <div className="hero-desc"><span>Satyendra </span>{item.desc}</div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default HeroSection;
