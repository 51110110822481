import React from 'react'
import './home.css'
import LanguageIcon from '@mui/icons-material/Language';
import HelpIcon from '@mui/icons-material/Help';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { useAuth } from '../../../provider/authProvider';

function Home() {
    const {getUserName} = useAuth();
    return (
        <>
            <div className='header'>
                <h2 style={{ fontWeight: 700 }}>Hi, {getUserName()}</h2>
            </div>

            <section className="new-template">
                <div className="template-info col-lg-5 mr-5">
                    <div className="row">
                        <div className="col-md-4 image">
                            <LanguageIcon sx={{ fontSize: 100 }} />
                        </div>
                        <div className="col">
                            <h2 style={{ fontWeight: 700 }}>New template</h2>
                            <p>We've added a few sharing ideas you might be interested in</p>
                            <button className="new-button">NEW →</button>
                        </div>
                    </div>
                </div>
                <div className="new-bookings col">
                    <p style={{ fontWeight: 600, color: "#474545c2" }}>New Bookings</p>
                    <div className='icon'>
                        <img src="/asset/newbookings.png" alt="" height={150} />
                    </div>
                </div>
            </section>

            <section className="help-section">
                <h2 style={{ fontWeight: 700 }}>Need some help?</h2>
                <div className="help-options">
                    <div className="help-card">
                        <div className="image-container">
                            <FeedbackIcon sx={{ fontSize: 50 }} />
                        </div>
                        <div className="text-container">
                            <h3>Feedback</h3>
                            <a href="#" style={{ fontWeight: 700 }}>Request feature</a>
                            <p>Share your feature request or feedback, we're all ears</p>
                        </div>
                    </div>
                    <div className="help-card">
                        <div className="image-container">
                            <HelpIcon sx={{ fontSize: 50 }} />
                        </div>
                        <div className="text-container">
                            <h3>Support</h3>
                            <a href="#" style={{ fontWeight: 700 }}>Help centre</a>
                            <p>Reach out to community, support or your personal assistant</p>
                        </div>
                    </div>
                    <div className="help-card">
                        <div className="image-container">
                            <LocalAtmIcon sx={{ fontSize: 50 }} />
                        </div>
                        <div className="text-container">
                            <h3>Earn More</h3>
                            <a href="#" style={{ fontWeight: 700 }}>Invite and earn</a>
                            <p>Invite your creator friends to topmate and get 40% of their commission for 6 months</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home