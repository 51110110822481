import { NavLink, useNavigate } from "react-router-dom";
import "../Navbar/Navbar.css";
import "./Login.css";
import { useState } from "react";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import img from "../../assets/sign-in-back.svg";
// import googleLogo from "../../assets/google-logo.webp";
import axios from "axios";
import { handleError, handleSuccess } from "../../Utils";
import { useAuth } from "../../provider/authProvider";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const {setToken} = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // creating new user profile
      const response = await axios.post("http://localhost:4000/api/v1/login", {
        userId,
        password,
      });

      const { jwtToken, message, name, success } = response.data;

      if (success) {
        handleSuccess(message);
        setToken(jwtToken);
        localStorage.setItem("loggedInUser", name);
        setUserId("");
        setPassword("");
        navigate('/dashboard');

      }

    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  return (
    <div className="flex container login margin-top">
      <div className="left flex-col">
        <div>
          <form onSubmit={handleSubmit} className="login-form flex-col">
            <div className="form-group flex-col">
              <label htmlFor="email">Email/Phone : </label>
              <input
                type="text"
                id="userId"
                value={userId}
                placeholder="Email/Phone"
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>

            <div className="form-group flex-col">
              <label htmlFor="password">Password : </label>
              <input
                type="password"
                id="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button type="submit" className="primary-btn submit-btn">
              Login
            </button>
            <NavLink to={"/signup"} className="forgot-password hover">
              Don't have an account?
            </NavLink>
          </form>
        </div>
        <div className="divider">
          <hr className="divider-line" />
        </div>
        <NavLink to={"/forgot-password"} className="forgot-password hover">
          Forgot Password
        </NavLink>
      </div>

      <div className="right">
        <img src={img} alt="Img" />
      </div>
    </div>
  );
};

export default Login;
