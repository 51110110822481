import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../Navbar/Navbar.css";
import "./Login.css";
import { useState } from "react";
import img from "../../assets/sign-in-back.svg";
import { handleError, handleSuccess } from "../../Utils";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return handleError("Please provide an email");
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      return handleError("Please Enter Valid Email.");
    }

    try {
      
      await axios.post('http://localhost:4000/api/v1/forget-password', {email});
      const response = await axios.post(
        "http://localhost:4000/api/v1/send-email-otp",
        { email }
      );
      handleSuccess(response.data.message);
      setTimeout(() => {
        navigate('/new-password', { state: { email } });
      }, 800);
      
    } catch (error) {
      return handleError(error.response.data.message);
    }
  };

  return (
    <div className="flex container login">
      <div className="left flex-col">
        <div className="left-mid">
          <h1>Forgot your password?</h1>
          <p>
            To reset your password, please enter the email address of your
            CodeQueue account.
          </p>
        </div>

        <div>
          <form onSubmit={handleSubmit} className="login-form flex-col">
            <div className="form-group flex-col">
              <label htmlFor="email">Email : </label>
              <input
                type="text"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button type="submit" className="primary-btn submit-btn">
              Reset Password
            </button>
          </form>
        </div>
        <div className="divider">
          <hr className="divider-line" />
        </div>
        <NavLink to={"/contact"} className="forgot-password hover">
          Contact Us
        </NavLink>
      </div>

      <div className="right">
        <img src={img} alt="Img" />
      </div>
    </div>
  );
};

export default ForgotPassword;
