import React from "react";
import Testimonials from "../Testimonials/Testimonials";
import HeroSection from "../Hero/HeroSection";
import './Home.css'
import FAQ from "../../Pages/Faqs/FAQ";

const Home = () => {
  return (
    <div className="home-page">
      <HeroSection/>
      <Testimonials />
      <FAQ/>
    </div>
  );
};

export default Home;
