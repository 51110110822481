import React, { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../provider/authProvider";
import Nav from "./Appbar";
import ComingSoon from "../../Pages/ComingSoon/ComingSoon";

const Navbar = () => {
  const [toggle, setToggle] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const contentToggleRef = useRef(null);
  const toggleBtnRef = useRef(null);
  const { isLoggedIn } = useAuth();

  const toggleBtn = () => {
    setToggle(!toggle);
  };

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (
      contentToggleRef.current &&
      !contentToggleRef.current.contains(event.target) &&
      !toggleBtnRef.current.contains(event.target)
    ) {
      setToggle(true); // Set the toggle to true to hide the popup
    }
  };

  const handleNavLinkClick = (e) => {
    setToggle(true); // Set the toggle to true to hide the popup
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="nav flex px-3">
        <div className="nav-left flex">
          <div className="brand-text">
            <Link to={"/"}> 
              <span style={{ color: "black" }}>Code</span>
              <span style={{ color: "var(--primarty-text-color)" }}>Queue</span>
            </Link>
          </div>
        </div>

        <div
          ref={contentToggleRef}
          className={toggle ? "nav-right flex" : "contentToggle"}
        >
          <div className={`nav-right-left flex ${toggle ? "" : "LeftContentToggle"}`}>
            <NavLink to={"/"} className="hover" onClick={handleNavLinkClick}>
              Home
            </NavLink>
            <li className="hover" onClick={setIsVisible}>
              About
            </li>
            <li className="hover" onClick={setIsVisible}>
              Contact
            </li>
            <li className="hover" onClick={setIsVisible}>
              Careers
            </li>
          </div>

          {isLoggedIn() ? <div className={`nav-right-right flex ${toggle ? "" : "LeftContentToggle"}`}>
            <Nav />
          </div>
            :
            <div className={`nav-right-right flex ${toggle ? "" : "LeftContentToggle"}`}>
              <NavLink to={"/login"} className="secondary-btn">
                Login
              </NavLink>
              <NavLink to={"/signup"} className="primary-btn" onClick={handleNavLinkClick} style={{ color: "white" }}>
                Sign up free
              </NavLink>
            </div>
          }
        </div>

        <div ref={toggleBtnRef} className="toggleBtn flex">
          <MenuIcon onClick={toggleBtn} />
        </div>
      </div>
      <ComingSoon isVisible={isVisible} setIsVisible={setIsVisible} />
    </>
  );
};

export default Navbar;
